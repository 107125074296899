export const content = {
  "CN-ZH": {
    header: "版权公告",
    updatedAt: `最后更新日期：2022-01-16`,
    paragraph: [
      `本网站的内容（包括但不限于所有文字、绘图、图画、图解、照片，亦包括但不限于数据或其他材料的汇编），均受到或其他实体所拥有的版权所规限。除了在本网站内获明文准许或事先得到机电工程署的书面授权外，严禁将该等版权作品复制、改编、分发、散布，或提供予公众。`
    ],
    notice: "免责声明",
    noticeContent: `本网站列载的资料由中华人民共和国香港特别行政区政府（"政府"）辖下的机电工程署编制，只供一般参考。政府虽已尽力确保该等一般资料准确，但对于该等资料在任何特定情况下使用时的准确性或恰当性，并没有作出任何明示或隐含的陈述、申述、保证或担保。
    <br /><br />由于香港地势复杂，城市化程度差异很大，因此太阳辐照在香港不同地区有很大差异。在航空照片拍摄后才建成的建筑物或才改动的现有建筑物屋顶将不会反映在太阳辐照图中。而太阳辐照图仅用于提供信息，以及太阳能初步评估工具。不能作为安装太阳能发电系统的决策资讯。
    <br /><br />对于因或就本网站所载的任何资料（包括数据或程式）而引起的任何损失或损害，政府并不承担责任。
    政府保留权利，可随时运用其绝对酌情决定权，省略、暂停或编辑本网站所载由政府编制的资料，而无须给予任何理由，亦无须事先通知。使用者有责任自行评估本网站所载的一切资料，并宜加以核实，在根据该等资料行事之前征询独立意见。`,
    accessibility: {
      title: "无障碍网页守则",
      text:
        "我们承诺尽力确保本网站符合万维网联盟(W3C)《无障碍网页内容指引》(WCAG)2.1 AA级别标准，但由于「香港太阳辐照图」包含彩色辐照图、数码地图及与建筑物相关的数据，因此要符合所有AA级别标准的规定而不依赖颜色来传递太阳辐照信息、为所有信息提供文字描述，及只使用键盘来操作有关控制地图的功能是并不可能。"
    }
  },
  "CN-TW": {
    header: "版權公告",
    updatedAt: `最後更新日期：2022-01-16`,
    paragraph: [
      `本網站的內容（包括但不限於所有文字、繪圖、圖畫、圖解、照片，亦包括但不限於數據或其他材料的匯編），均受到或其他實體所擁有的版權所規限。除了在本網站內獲明文准許或事先得到機電工程署的書面授權外，嚴禁將該等版權作品複製、改編、分發、散布，或提供予公眾。`
    ],
    notice: "免責聲明",
    noticeContent: `本網站列載的資料由中華人民共和國香港特別行政區政府（"政府"）轄下的機電工程署編製，只供一般參考。政府雖已盡力確保該等一般資料準確，但對於該等資料在任何特定情況下使用時的準確性或恰當性，並沒有作出任何明示或隱含的陳述、申述、保證或擔保。
    <br /><br />於香港地勢複雜，城市化程度差異很大，因此太陽輻照在香港不同地區有很大差異。在航空照片拍攝後才建成的建築物或才改動的現有建築物屋頂將不會反映在太陽輻照圖中。而太陽輻照圖僅用於提供信息，以及太陽能初步評估工具。不能作為安裝太陽能發電系統的決策資訊。
    <br /><br />對於因或就本網站所載的任何資料（包括數據或程式）而引起的任何損失或損害，政府並不承擔責任。
    政府保留權利，可隨時運用其絕對酌情決定權，省略、暫停或編輯本網站所載由政府編製的資料，而無須給予任何理由，亦無須事先通知。使用者有責任自行評估本網站所載的一切資料，並宜加以核實，在根據該等資料行事之前徵詢獨立意見。
    `,
    accessibility: {
      title: "無障礙網頁守則",
      text:
        "我們承諾盡力確保本網站符合萬維網聯盟(W3C)《無障礙網頁內容指引》(WCAG)2.1 AA級別標準，但由於「香港太陽輻照圖」包含彩色輻照圖、數碼地圖及與建築物相關的數據，因此要符合所有AA級別標準的規定而不依賴顏色來傳遞太陽輻照資訊、為所有資訊提供文字描述，及只使用鍵盤來操作有關控制地圖的功能是並不可能。"
    }
  },
  ENG: {
    header: "Copyright Notice",
    updatedAt: `Last Revision Date: 2022-01-16`,
    paragraph: [
      `The content available on this website, including but not limited to all text, graphics, drawings, diagrams, photographs and compilation of data or other materials are subject to copyright owned by the Government or other entities. Except as expressly permitted herein or where prior written authorization is obtained from the Electrical and Mechanical Services Department, any reproduction, adaptation, distribution, dissemination or making available of such copyright works to the public is strictly prohibited.`
    ],
    notice: "Disclaimer",
    noticeContent: `
    The information contained in this website is compiled by the Electrical and Mechanical Services Department of the Government of the Hong Kong Special Administrative Region of the People’s Republic of China (“the Government”) for general information only. Whilst the Government endeavours to ensure the accuracy of this general information, no statement, representation, warranty or guarantee, express or implied, is given as to its accuracy or appropriateness for use in any particular circumstances. 
    <br /><br />As Hong Kong has complex topography and large variations in the extent of urbanization, there are significant variations in solar irradiation over different parts of the territory. For those new buildings constructed or the changes in existing building rooftops appeared after the photographs were taken in 2019, they will not be reflected in the Solar Irradiation Map. The Solar Irradiation Map is intended for information purposes only and as a preliminary solar assessment tool. It is not intended to be used as a decision making source of information for solar photovoltaic (PV) installations. 
    <br /><br />The Government is not responsible for any loss or damage whatsoever arising out of or in connection with any information including data or programmes on this website. The Government reserves the right to omit, suspend or edit all information compiled by the Government in this website at any time in its absolute discretion without giving any reason or prior notice. Users are responsible for making their own assessment of all information contained in this website and are advised to verify such information by obtaining independent advice before acting upon it.
    `,
    accessibility: {
      title: "Web Accessibility Conformance",
      text:
        "We are committed to ensuring that our website conforms to the World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) 2.1 Level AA requirements to the maximum extent possible.   However, as our website contains colour solar irradiation map, digital maps, and building related data, it is impossible to incorporate all Level AA accessibility requirements such as to convey the map information without relying on colours, provide text descriptions for all map images and control map functions simply using keyboards."
    }
  }
};
